// @flow

import React from 'react';

import { injectIntl, type IntlShape } from 'react-intl';
import defaultTranslations from '../../../common/lang/default';

import {
    EMAIL,
    TELEPHONE,
    ADDRESS_1,
    ADDRESS_2,
    ADDRESS_3,
    BUSINESS_NAME,
} from '../../../common/constants/business-properties';

import locationIcon from '../../../img/icons/icono-posicion.svg';
import mailIcon from '../../../img/icons/icono-mail.svg';
import telephoneIcon from '../../../img/icons/icono-telef.svg';
import webIcon from '../../../img/icons/icono-idioma.svg';
import './styles.scss';

type Props = {|
    intl: IntlShape,
|};

const ContactDetails = ({ intl }: Props) => (
    <div className="contact-details__container">
        <div className="contact-details__container__title">
            <strong>
                {intl.formatMessage({
                    id: 'contact_experience',
                    defaultMessage: defaultTranslations.contact_experience,
                })}
            </strong>
            ,{' '}
            {intl.formatMessage({
                id: 'contact_dedication',
                defaultMessage: defaultTranslations.contact_dedication,
            })}
            ,{' '}
            <strong>
                {intl.formatMessage({
                    id: 'contact_proficiency',
                    defaultMessage: defaultTranslations.contact_proficiency,
                })}
            </strong>
            .
        </div>
        <div className="contact-details__container__info-block">
            <img
                src={locationIcon}
                alt=""
                className="contact-details__container__info-block__icon"
            />
            <div className="contact-details__container__info-block__info address">
                <div>
                    <strong>{BUSINESS_NAME}</strong>
                </div>
                <div className="contact-details__container__info-block__info__sub">
                    {ADDRESS_1}
                </div>
                <div className="contact-details__container__info-block__info__sub">
                    {`${ADDRESS_2}, ${ADDRESS_3}`}
                </div>
            </div>
        </div>
        <div className="contact-details__container__info-block">
            <img
                src={telephoneIcon}
                alt=""
                className="contact-details__container__info-block__icon"
            />
            <div className="contact-details__container__info-block__info">
                {TELEPHONE}
            </div>
        </div>
        <div className="contact-details__container__info-block">
            <img
                src={mailIcon}
                alt=""
                className="contact-details__container__info-block__icon"
            />
            <div className="contact-details__container__info-block__info">
                {EMAIL}
            </div>
        </div>
        <div className="contact-details__container__info-block">
            <img
                src={webIcon}
                alt=""
                className="contact-details__container__info-block__icon"
            />
            <div className="contact-details__container__info-block__info">
                <a
                    href="https://intraobes.com/denia"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Intraobes Denia
                </a>
            </div>
        </div>
    </div>
);

export default injectIntl(ContactDetails);
