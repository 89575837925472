// @flow

import React from 'react';
import './styles.scss';

type Props = {|
    label: string,
    type: string,
    name: string,
    required: boolean,
    onChange: value => void,
    value: mixed,
    maxlength: number,
|};

const Input = ({
    label,
    type,
    required = false,
    name,
    onChange,
    value,
    maxlength = 200,
}: Props) => (
    <div className="input__container">
        <label className="input__container__label">
            {label} {required ? '*' : ''}
            {type === 'textarea' ? (
                <textarea
                    name={name}
                    cols="30"
                    rows="6"
                    className="input__container__textarea"
                    data-gramm_editor="false"
                    onChange={onChange}
                    value={value}
                    required={required}
                    maxLength={maxlength}
                />
            ) : (
                <input
                    type={type}
                    className="input__container__field"
                    name={name}
                    onChange={onChange}
                    value={value}
                    required={required}
                    maxLength={maxlength}
                />
            )}
        </label>
    </div>
);

export default Input;
