import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ObesityImg from '../../../img/photos/servicio_1.jpg';
import PageBase from '../../../components/base/page';
import ListBase from '../common/BaseList';
import defaultTranslations from '../../../common/lang/default';

const toBase = '/servicios/obesidad/';
const list = [
    {
        to: 'balon-gastrico',
    },
    {
        to: 'plicatura-gastrica',
    },
    {
        to: 'manga-gastrica',
    },
    {
        to: 'by-pass-gastrico',
    },
];

const ObesityServices = ({ intl }) => (
    <PageBase>
        <ListBase
            img={ObesityImg}
            title={intl.formatMessage({
                id: 'service_1_subtitle',
                defaultMessage: defaultTranslations.service_1_subtitle,
            })}
        >
            <ul>
                {list.map((item, ind) => {
                    const stringId = 'services_obesity-section_list-' + ind;
                    return (
                        <li key={ind}>
                            <Link to={toBase + item.to}>
                                {intl.formatMessage({
                                    id: stringId,
                                    defaultMessage:
                                        defaultTranslations[stringId],
                                })}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </ListBase>
    </PageBase>
);

export default injectIntl(ObesityServices);
