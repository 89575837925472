import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import defaultTranslations from '../../../common/lang/default';
import { Grid, Container } from 'semantic-ui-react';
import obesityService_1 from '../../../img/photos/Balon6Meses_BN_sq2.jpg'; // balon
import obesityService_2 from '../../../img/photos/PlicaturaBN_sq2.jpg'; // plicatura
import obesityService_3 from '../../../img/photos/MangaBN_sq2.jpg'; // manga
import obesityService_4 from '../../../img/photos/ByPassBN_sq2.jpg'; // by-pass

import './styles.scss';

const SERVICES = [
    {
        text_id: 'services_obesity-section_list-0',
        text_default: defaultTranslations['services_obesity-section_list-0'],
        img: obesityService_1,
        to: '/servicios/obesidad/balon-gastrico',
    },
    {
        text_id: 'services_obesity-section_list-1',
        text_default: defaultTranslations['services_obesity-section_list-1'],
        img: obesityService_2,
        to: '/servicios/obesidad/plicatura-gastrica',
    },
    {
        text_id: 'services_obesity-section_list-2',
        text_default: defaultTranslations['services_obesity-section_list-2'],
        img: obesityService_3,
        to: '/servicios/obesidad/manga-gastrica',
    },
    {
        text_id: 'services_obesity-section_list-3',
        text_default: defaultTranslations['services_obesity-section_list-3'],
        img: obesityService_4,
        to: '/servicios/obesidad/by-pass-gastrico',
    },
];

const ServicesMvp = ({ intl }) => (
    <div className="services__container">
        <button className="services__container__anchor" id="servicios" />
        <div className="services__container__title">
            {intl.formatMessage({
                id: 'services_title',
                defaultMessage: defaultTranslations.services_title,
            })}{' '}
            de obesidad
        </div>
        <Container>
            <Grid padded="horizontally" style={{ width: '100%' }}>
                {SERVICES.map(service => {
                    return (
                        <Grid.Column
                            key={service.text_id}
                            mobile={8}
                            tablet={4}
                            computer={4}
                            as={Link}
                            to={service.to}
                        >
                            <div
                                className="services__container__img__container"
                                style={{
                                    height: '100px',
                                    width: '100px',
                                    margin: '0 auto',
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={service.img}
                                    alt="service type"
                                    style={{ height: '100%', width: '100%' }}
                                />
                                <div
                                    className="services__container__img__container__bg"
                                    style={{
                                        background: 'rgba(19,64,130,0.25)',
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: '0',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>

                            <div className="services__container__subtitle">
                                {intl.formatMessage({
                                    id: service.text_id,
                                    defaultMessage: service.text_default,
                                })}
                            </div>
                        </Grid.Column>
                    );
                })}
            </Grid>
        </Container>
    </div>
);

export default injectIntl(ServicesMvp);
