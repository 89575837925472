import React from 'react';
import { injectIntl } from 'react-intl';
import ObesityImg from '../../../../img/photos/Balon6Meses_BN.jpg';
import ObesityImgBlur from '../../../../img/photos/Balon6Meses_BN_blur.jpg';
import PageBase from '../../../../components/base/page';
import BaseContent from '../../common/BaseContent';

const ObesityMangaGastrica = ({ intl }) => (
    <PageBase>
        <BaseContent
            img={ObesityImg}
            imgThumb={ObesityImgBlur}
            title={intl.formatMessage({
                id: 'services_obesity-section_list-0',
                defaultMessage: 'services_obesity-section_list-0',
            })}
            subtitle={intl.formatMessage({
                id: 'service_1_subtitle',
                defaultMessage: 'service_1_subtitle',
            })}
            mainHeading="¿En qué consiste el Balón gástrico?"
            mainDescription="El balón gástrico es un dispositivo esférico que se introduce en el estomago y provoca una reducción de su capacidad. <br /><br /> Produce una saciedad precoz con la consiguiente disminución de la ingesta, facilitando la perdida de peso."
        >
            <h4>Información para el paciente</h4>
            <ul>
                <li>Esta indicado para pacientes con IMC 28-40</li>
                <li>Duración de la técnica: 20 minutos</li>
                <li>Sin ingreso hospitalario</li>
                <li>Sin anestesia ni endoscopia</li>
                <li>Reincorporación a la actividad habitual 2-3 días</li>
                <li>
                    Seguimientos durante 12 meses por médico, psicología y
                    nutrición
                </li>
                <li>Eliminación por vía natural a los 4 meses</li>
            </ul>
            <h4>Ventajas</h4>
            <p>Sencillo, seguro y rápido</p>
            <h4>Invonvenientes</h4>
            <p>
                Técnica TEMPORAL para perder peso. Elevada incidencia de
                recuperación de peso sobre todo en pacientes con IMC &gt; 35
            </p>
            <h4>Grado de recomendación</h4>
            <p>Elevado</p>
            <h4>Resultados</h4>
            <li>Pérdida del 20-50% del exceso de peso</li>
            <li>Pérdida de peso media: 15Kg</li>
            <h4>Videos</h4>
            <p>
                <a
                    href="https://www.youtube.com/watch?v=Nw9nsfwu3KY"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Balón ingerible-digerible Elipse
                </a>
            </p>
            <h4>Preguntas frecuentes</h4>
            <li>
                <strong>¿Con qué se rellena el balón?</strong>
                <br /> El balón elipse se rellena con 550ml de agua purificada,
                con un tampón de pH y un agente conservanta
            </li>
            <br />
            <li>
                <strong>
                    Me voy de vacaciones pronto, ¿puedo volar llevando el balón?
                </strong>
                <br /> Si, se puede volar. Sin restricciones. Recomendamos
                llevar el informe médico que se le entrega tras la colocación
                del balón.
            </li>
            <br />
            <li>
                <strong>¿Cuanto peso puedo llegar a perder?</strong>
                <br /> Siguiendo las recomendaciones de forma estricta, usted
                puede esperar perder alrededor de 15Kg. Algunos pacientes
                pierden hasta 25Kg.
            </li>
            <br />
            <li>
                <strong>¿Como se inserta el balón?</strong>
                <br /> El balón se inserta tragando la cápsula, que lleva un
                fino cateter conectado. Una vez que se ha tragado la cápsula,
                hacemos una radiografía para comprobar su correcta posición.
                Verificado esto, llenamos el balón con 550ml de agua y
                posteriormente retiramos el cateter.
            </li>
            <br />
            <li>
                <strong>
                    Estoy embarazada, ¿puedo ponerme el balón gástrico?
                </strong>
                <br /> No.
            </li>
            <br />
            <li>
                <strong>
                    Tengo un balón puesto, ¿qué pasa si me quedo embarazada?
                </strong>
                <br />
                Debemos retirar el balón inmediatamente.
            </li>
            <br />
        </BaseContent>
    </PageBase>
);

export default injectIntl(ObesityMangaGastrica);
