import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import ReactGA from 'react-ga';

import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Container, useState } from './state-containers/user-properties';
import getLocaleMessages from './common/utils/getLocaleMessages';

import WebContainer from './web-container';

import './App.scss';

// Init analytics
ReactGA.initialize('UA-146622091-1');

addLocaleData([...en, ...es]);

const App = () => {
    const [{ lang }] = useState();

    return (
        <IntlProvider locale={lang} messages={getLocaleMessages(lang)}>
            <Router>
                <WebContainer />
            </Router>
        </IntlProvider>
    );
};

const ContainedApp = props => (
    <Container>
        <App {...props} />
    </Container>
);

export default ContainedApp;
