import React from 'react';
import { Grid, Responsive, Container } from 'semantic-ui-react';
import ContactForm from '../../../../components/form';
import ProgressiveImage from '../../../../components/image';
import ServiciosMvp from '../../../home/servicios-mvp';
import telephoneIcon from '../../../../img/icons/icono-telef.svg';
import FirstFree from '../../../home/first-free';
import CallUs from '../../../home/call-us';
import { TELEPHONE } from '../../../../common/constants/business-properties';

import './styles.scss';

const ListBase = ({
    img,
    imgThumb,
    title,
    subtitle,
    mainHeading,
    mainDescription,
    children,
}) => (
    <>
        <Responsive
            as={Grid.Row}
            className="content-base__container"
            maxWidth={720}
        >
            <div
                className="image-container"
                style={{
                    position: 'relative',
                    display: 'flex',
                }}
            >
                <ProgressiveImage
                    main={img}
                    thumb={imgThumb}
                    name="imagen de cabecera"
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        background: 'rgba(19,64,130,0.45)',
                        height: '100%',
                        width: '100%',
                    }}
                />
                <span className="content-base__container__title">{title}</span>
                <span className="content-base__container__subtitle">
                    {subtitle}
                </span>
            </div>
            <div className="content-base__container__section">
                <h1>{mainHeading}</h1>
                <h3
                    dangerouslySetInnerHTML={{ __html: mainDescription }}
                ></h3>{' '}
                {children}
            </div>
            <div className="content-base__container__section contact">
                <h1>CONTACTAR</h1>
                <div className="telphone">
                    <img src={telephoneIcon} alt="" />
                    <u>
                        <a href={`tel: ${TELEPHONE}`}>{TELEPHONE}</a>
                    </u>
                </div>
            </div>
            <div className="content-base__container__section">
                <ContactForm />
            </div>
            <ServiciosMvp />
        </Responsive>
        <Responsive
            as={Grid.Row}
            className="content-base__container__large"
            minWidth={720}
        >
            <Container>
                <Grid.Column>
                    <Grid.Row>
                        <Grid
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Grid.Column
                                className="content-base__container__large__header-column"
                                width={6}
                            >
                                <div
                                    className="image-container"
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                    }}
                                >
                                    {/* <img
                                        src={img}
                                        alt="imagen de cabecera"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    /> */}
                                    <ProgressiveImage
                                        main={img}
                                        thumb={imgThumb}
                                        name="imagen de cabecera"
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            background: 'rgba(19,64,130,0.45)',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    />
                                    <span className="content-base__container__large__title">
                                        {title}
                                    </span>
                                    <span className="content-base__container__large__subtitle">
                                        {subtitle}
                                    </span>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                style={{
                                    paddingLeft: '60px',
                                }}
                                width={10}
                            >
                                <h1 className="main-heading">{mainHeading}</h1>
                                <h3
                                    className="main-description"
                                    dangerouslySetInnerHTML={{
                                        __html: mainDescription,
                                    }}
                                ></h3>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>
                    <div className="content-base__container__large__section">
                        {children}
                    </div>
                    <ServiciosMvp />
                </Grid.Column>
                <br></br>
                <br></br>
            </Container>
            <Grid padded="horizontally" style={{ width: '100%' }}>
                <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    className="home__always-blue-bg"
                >
                    <Grid
                        padded="vertically"
                        columns="one"
                        reversed="mobile vertically"
                        centered
                    >
                        <Grid.Column as={CallUs} />
                        <Grid.Column as={FirstFree} />
                    </Grid>
                </Grid.Column>
                <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    className="home__form_container"
                >
                    <ContactForm withTitle />
                </Grid.Column>
            </Grid>
        </Responsive>
    </>
);

export default ListBase;
