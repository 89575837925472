// @flow

import React from 'react';
import { G_MAPS_KEY } from '../../common/constants/map-properties';

import Map from './view';

type Props = {|
    width: string,
|};

const ConfiguredMap = ({ width }: Props) => (
    <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${G_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: '100%', width }} />}
        containerElement={<div style={{ height: '425px', width }} />}
        mapElement={<div style={{ height: '100%', width }} />}
    />
);

export default ConfiguredMap;
