import React from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

const SqRoute = ({ render: Render, ...props }) => (
    <Route
        render={() => {
            ReactGA.pageview(props.path);
            return <Render />;
        }}
        {...props}
    />
);

export default SqRoute;
