// @flow

import EN_TRANSLATIONS from "../lang/en";
import ES_TRANSLATIONS from "../lang/es";

export default lang => {
    switch (lang) {
        case "en":
            return EN_TRANSLATIONS;
        case "es":
        default:
            return ES_TRANSLATIONS;
    }
};
