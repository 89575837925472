import React from 'react';
import { Grid } from 'semantic-ui-react';

import './styles.scss';

const ListBase = ({ img, title, children }) => (
    <Grid.Row className="list-base__container">
        <div
            className="image-container"
            style={{
                background: `url(${img}) no-repeat, rgba(19,64,130,0.45)`,
                backgroundSize: 'cover',
                backgroundBlendMode: 'soft-light',
            }}
        />
        <span className="list-base__container__title">{title}</span>
        {children}
    </Grid.Row>
);

export default ListBase;
