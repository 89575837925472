import React from 'react';
import { injectIntl } from 'react-intl';
// import defaultTranslations from '../../../common/lang/default';
import ProgressiveImage from '../../../components/image';
// import carouselImg from '../../../img/photos/2018-11-29_Bordallo-2090_bw.jpg';
import carouselImg from '../../../img/photos/2018-11-29_Bordallo-2090_bw_half.jpg';
import carouselImgBlur from '../../../img/photos/2018-11-29_Bordallo-2090_bw_half_blur.jpg';

// import { Button, Icon } from 'semantic-ui-react';

import './styles.scss';

const HomeCarousel = ({ intl }) => (
    <div className="carousel_slide__container">
        <div className="carousel_slide__container__img-holder">
            <ProgressiveImage
                main={carouselImg}
                thumb={carouselImgBlur}
                name="carrusel"
            />
            {/* <img src={carouselImg} alt="imagen de cabecera" /> */}
            <div className="overlay"></div>
        </div>
        <div className="carousel_slide__container__text">
            Bienvenido a Cirugía Bordallo
            {/* {intl.formatMessage({
                id: 'carousel_1_title',
                defaultMessage: defaultTranslations.carousel_1_title,
            })} */}
        </div>
        {/* <div className="carousel_slide__container__button_container">
            <Button inverted>
                {intl.formatMessage({
                    id: 'carousel_action',
                    defaultMessage: defaultTranslations.carousel_action,
                })}
                <Icon name="right arrow" />
            </Button>
        </div> */}
    </div>
);

export default injectIntl(HomeCarousel);
