// @flow

export const TELEPHONE = '+34 659 58 46 89';

export const EMAIL = 'consulta@cirugiabordallo.com';

export const DOMAIN = 'cirugiabordallo.com';

export const BUSINESS_NAME = 'Cirugía Bordallo';

export const ADDRESS_1 = 'C/ Marqués de Campo 37,';

export const ADDRESS_2 = '2º Puerta 2';

export const ADDRESS_3 = 'Denia. Alicante';
