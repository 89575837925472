import React, { useEffect } from 'react';
import './styles.scss';

let hasLoadedBefore = false;

const ProgressiveImage = ({ main, thumb, name }) => {
    useEffect(() => {
        const pItem = document.getElementsByClassName('progressive replace');
        let timer;
        function inView() {
            var wT = window.pageYOffset,
                wB = wT + window.innerHeight,
                cRect,
                pT,
                pB,
                p = 0;
            while (p < pItem.length) {
                cRect = pItem[p].getBoundingClientRect();
                pT = wT + cRect.top;
                pB = pT + cRect.height;

                if (wT < pB && wB > pT) {
                    loadFullImage(pItem[p]);
                    pItem[p].classList.remove('replace');
                } else p++;
            }

            hasLoadedBefore = true;
        }

        // replace with full image
        function loadFullImage(item) {
            if (!item || !item.href) return;

            // load image
            var img = new Image();
            if (item.dataset) {
                img.srcset = item.dataset.srcset || '';
                img.sizes = item.dataset.sizes || '';
            }
            img.src = item.href;
            img.className = 'reveal';
            if (img.complete) addImg();
            else img.onload = addImg;

            // replace image
            function addImg() {
                // disable click
                item.addEventListener(
                    'click',
                    function(e) {
                        e.preventDefault();
                    },
                    false
                );

                // add full image
                item.appendChild(img).addEventListener('animationend', function(
                    e
                ) {
                    // remove preview image
                    var pImg =
                        item.querySelector && item.querySelector('img.preview');

                    if (pImg) {
                        e.target.alt = pImg.alt || '';
                        item.removeChild(pImg);
                        e.target.classList.remove('reveal');
                    }
                });
            }
        }

        function scroller() {
            timer =
                timer ||
                setTimeout(function() {
                    timer = null;
                    requestAnimationFrame(inView);
                }, 300);
        }

        if (!hasLoadedBefore) {
            window.addEventListener('load', inView);
        } else {
            inView();
        }
        window.addEventListener('scroll', scroller, false);
        window.addEventListener('resize', scroller, false);

        return () => {
            window.removeEventListener('scroll', scroller);
            window.removeEventListener('resize', scroller);
            window.removeEventListener('load', inView);
        };
    }, []);

    return (
        <a href={main} className="progressive replace">
            <img src={thumb} className="preview" alt={name || ''} />
        </a>
    );
};

export default ProgressiveImage;
