import React from 'react';
import { injectIntl } from 'react-intl';
import ObesityImg from '../../../../img/photos/PlicaturaBN.jpg';
import ObesityImgBlur from '../../../../img/photos/PlicaturaBN_blur.jpg';
import PageBase from '../../../../components/base/page';
import BaseContent from '../../common/BaseContent';

const ObesityPlicaturaGastrica = ({ intl }) => (
    <PageBase>
        <BaseContent
            img={ObesityImg}
            imgThumb={ObesityImgBlur}
            title={intl.formatMessage({
                id: 'services_obesity-section_list-1',
                defaultMessage: 'services_obesity-section_list-1',
            })}
            subtitle={intl.formatMessage({
                id: 'service_1_subtitle',
                defaultMessage: 'service_1_subtitle',
            })}
            mainHeading="¿En qué consiste la Plicatura gástrica?"
            mainDescription="La Plicatura gástrica es Restricción DEFINITIVA del volumen del
            estómago de los pacientes. Ayuda a modificar hábitos
            alimentarios. Produce diminución de la ingesta, saciedad precoz,
            pérdidad de peso y disminución de masa corporal."
        >
            <h4>Información para el paciente</h4>
            <ul>
                <li>Esta indicado para pacientes con IMC 30-40</li>
                <li>Duración de la técnica 90 minutos con anestesia general</li>
                <li>Estancia en el hospital de 1 día</li>
                <li>Reincorporación a la actividad habitual 7 días</li>
                <li>
                    Seguimiento de 1 año después de la cirugía del paciente por
                    nuestro equipo. Después anualmente de por vida
                </li>
            </ul>
            <h4>Ventajas</h4>
            <p>
                Cirugía sin corte del estómago. Más segura que la manga o el
                bypass. Más efectiva que las técnicas restrictivas endoscópicas.
            </p>
            <h4>Invonvenientes</h4>
            <p>
                Produce menor restricción y disminución del apetito que la
                manga.
            </p>
            <h4>Grado de recomendación</h4>
            <p>
                Grado de recomendación con la evidencia científica disponible
                Elevada. Aprobada por la FDA americana y la SECO española.
            </p>
            <h4>Resultados</h4>
            <p>Pérdida del 50-60% del exceso de peso a los 9 meses.</p>
            <h4>Videos</h4>
            <p>
                <a
                    href="https://www.youtube.com/watch?v=Xj2VzhM-Ndk&feature=youtu.be"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Plicatura Gástrica 3D
                </a>
            </p>
            <p>
                <a
                    href="https://www.youtube.com/watch?v=2TV5mKdLhWg"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Secuencia real de cómo queda una plicatura gástrica
                </a>
            </p>
        </BaseContent>
    </PageBase>
);

export default injectIntl(ObesityPlicaturaGastrica);
