// @flow

import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';

import { GPS_MAIN_ADRESS, ZOOM } from '../../common/constants/map-properties';
import { BUSINESS_NAME } from '../../common/constants/business-properties';

import mapMarker from '../../img/icons/map-marker.svg';

const Map = () => (
    <GoogleMap defaultZoom={ZOOM} defaultCenter={GPS_MAIN_ADRESS}>
        <Marker
            position={GPS_MAIN_ADRESS}
            title={BUSINESS_NAME}
            icon={{
                url: mapMarker,
                scaledSize: new window.google.maps.Size(94, 72),
            }}
        />
    </GoogleMap>
);

export default withScriptjs(withGoogleMap(Map));
