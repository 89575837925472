import React from 'react';
import { Grid } from 'semantic-ui-react';
import Route from '../components/sq-route';

import Navigation from '../components/navigation';
import Footer from '../components/footer';

// Pages
import Home from '../routes/home';
import Services from '../routes/services';

const WebContainer = () => (
    <Grid padded>
        <Grid.Row as={Navigation} />
        <Grid.Row className="main-content__container">
            <Route path="/" exact render={Home} />
            <Services />
        </Grid.Row>
        <Grid.Row as={Footer} />
    </Grid>
);

export default WebContainer;
