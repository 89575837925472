import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';

import Input from '../input';
// import Select from '../select';
import Button from '../button';
import sendForm from '../../common/services/send-form';
import defaultTranslations from '../../common/lang/default';
import checkBlue from '../../img/icons/check.svg';
import errorIcon from '../../img/icons/error.svg';

import { useState as useUserState } from '../../state-containers/user-properties';

import './styles.scss';

const Form = ({ intl, withTitle }) => {
    const [{ userFP }] = useUserState();
    const [sending, setSending] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendFailure, setSendFailure] = useState(false);

    const handleSubmitForm = e => {
        e.preventDefault();

        const { target } = e;
        let message = {};

        for (let i = 0; i < target.length - 1; i++) {
            message[target[i].name] = target[i].value;
        }

        sendForm(message, userFP)
            .then(response => response.json())
            .then(msg => {
                setSending(false);
                if (msg.error) {
                    setSendFailure(true);
                } else {
                    setSendSuccess(true);
                }
            })
            .catch(() => {
                setSending(false);
                setSendFailure(true);
            })
            .finally(() => {
                const element =
                    document.querySelector('.form__success') ||
                    document.querySelector('.form__failure');
                element.scrollIntoView(true);
                window.scrollBy({ top: -60, behavior: 'smooth' });
            });

        setSending(true);
    };

    return (
        <Fragment>
            <div className={`form__success ${!sendSuccess ? 'hidden' : ''}`}>
                <img src={checkBlue} alt="Check icon" />
                <span>
                    {intl.formatMessage({
                        id: 'form_success',
                        defaultMessage: defaultTranslations.form_success,
                    })}
                </span>
            </div>
            <div
                className={`form__failure ${
                    !sendFailure || sendSuccess ? 'hidden' : ''
                }`}
            >
                <div className="form__failure__block">
                    <img src={errorIcon} alt="Check icon" />
                    <span>
                        {intl.formatMessage({
                            id: 'form_error',
                            defaultMessage: defaultTranslations.form_error,
                        })}
                    </span>
                </div>
            </div>
            <form
                className={`form__container ${
                    sendSuccess || sendFailure ? 'hidden' : ''
                }`}
                onSubmit={handleSubmitForm}
            >
                {withTitle && (
                    <div className="form__container__title">
                        {intl.formatMessage({
                            id: 'form_title',
                            defaultMessage: defaultTranslations.form_title,
                        })}
                    </div>
                )}
                <div className="form__container__subtitle">
                    {intl.formatMessage({
                        id: 'form_subtitle',
                        defaultMessage: defaultTranslations.form_subtitle,
                    })}
                </div>
                <Input
                    label={intl.formatMessage({
                        id: 'form_fullName',
                        defaultMessage: defaultTranslations.form_fullName,
                    })}
                    type="text"
                    name="fullName"
                    className="form__container__input"
                    maxlength={50}
                    required
                />
                <Input
                    label={intl.formatMessage({
                        id: 'form_email',
                        defaultMessage: defaultTranslations.form_email,
                    })}
                    type="email"
                    name="email"
                    className="form__container__input"
                    required
                />
                <Input
                    label={intl.formatMessage({
                        id: 'form_telephone',
                        defaultMessage: defaultTranslations.form_telephone,
                    })}
                    type="tel"
                    name="telephone"
                    className="form__container__input"
                    required
                />
                {/* <Select
                    name="contactMethod"
                    label={intl.formatMessage({
                        id: 'form_contactMethod',
                        defaultMessage: defaultTranslations.form_contactMethod,
                    })}
                    options={[
                        {
                            value: 'telephone',
                            label: intl.formatMessage({
                                id: 'form_telephone',
                                defaultMessage:
                                    defaultTranslations.form_telephone,
                            }),
                        },
                        {
                            value: 'email',
                            label: intl.formatMessage({
                                id: 'form_email',
                                defaultMessage: defaultTranslations.form_email,
                            }),
                        },
                    ]}
                /> */}
                <Input
                    label={intl.formatMessage({
                        id: 'form_motive',
                        defaultMessage: defaultTranslations.form_motive,
                    })}
                    type="textarea"
                    name="motive"
                    className="form__container__input"
                />
                <Button className="form__container__submit" disabled={sending}>
                    {intl.formatMessage({
                        id: 'form_send',
                        defaultMessage: defaultTranslations.form_send,
                    })}
                </Button>
            </form>
        </Fragment>
    );
};

export default injectIntl(Form);
