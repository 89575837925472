// @flow

import React from 'react';
import { injectIntl, type IntlShape } from 'react-intl';
import defaultTranslations from '../../../common/lang/default';

import './styles.scss';

type Props = {|
    intl: IntlShape,
|};

const FirstFree = ({ intl }: Props) => (
    <div className="first-free__container">
        <div className="first-free__container__title">
            {intl.formatMessage({
                id: 'firstFree_title',
                defaultMessage: defaultTranslations.firstFree_title,
            })}
        </div>
        <div className="first-free__container__subtitle">
            {intl.formatMessage({
                id: 'firstFree_subtitle',
                defaultMessage: defaultTranslations.firstFree_subtitle,
            })}
        </div>
    </div>
);

export default injectIntl(FirstFree);
