// @flow

import fetch from '../../utils/fetch';
import { BASE_URL } from '../constants';

type Message = {|
    fullName: string,
    email: string,
    telephone: string,
|};

const sendMailUrl = `${BASE_URL}sendMail`;

const sendMail = (message: Message, fp: string) => {
    const body = JSON.stringify(message);
    const headers = new Headers({
        Authentication: fp,
    });
    const opts = {
        method: 'POST',
        body,
        headers,
    };

    return fetch(sendMailUrl, opts);
};

export default sendMail;
