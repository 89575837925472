import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { injectIntl } from 'react-intl';
import chevronRight from '../../../img/icons/arrow-right.svg';
import chevronLeft from '../../../img/icons/arrow-left.svg';
import defaultTranslations from '../../../common/lang/default';
import { useState as useUserState } from '../../../state-containers/user-properties';

import './styles.scss';

const navigationMap = [
    {
        label_id: 'services',
        selected: ({ location: { pathname } }) =>
            pathname.startsWith('/servicios'),
        children: [
            {
                type: 'back',
                label_id: 'services',
            },
            {
                label_id: 'services_obesity',
                to: '/servicios/obesidad',
                selected: ({ location: { pathname } }) =>
                    pathname.startsWith('/servicios/obesidad'),
            },
            {
                label_id: 'services_laparoscopy',
                to: '/servicios/laparoscopia',
                selected: ({ location: { pathname } }) =>
                    pathname.startsWith('/servicios/laparoscopia'),
            },
            {
                label_id: 'services_breast',
                to: '/servicios/cirugia-de-mama',
                selected: ({ location: { pathname } }) =>
                    pathname.startsWith('/servicios/cirugia-de-mama'),
            },
            {
                label_id: 'services_conventional',
                to: '/servicios/cirugia-convencional',
                selected: ({ location: { pathname } }) =>
                    pathname.startsWith('/servicios/cirugia-convencional'),
            },
        ],
    },
    // {
    //     label_id: 'doctor',
    //     to: '/doctor-bordallo',
    //     selected: ({ location: { pathname } }) =>
    //         pathname.startsWith('/doctor-bordallo'),
    // },
    {
        label_id: 'faqs',
        to: '/faqs',
        selected: ({ location: { pathname } }) => pathname.startsWith('/faqs'),
    },
    {
        label_id: 'contact',
        to: '/contacto',
        selected: ({ location: { pathname } }) =>
            pathname.startsWith('/contacto'),
    },
    // {
    //     label_id: 'lang',
    //     children: [
    //         {
    //             type: 'back',
    //             label_id: 'lang',
    //         },
    //         {
    //             label_id: 'lang_spanish',
    //             locale: 'es',
    //             selected: ({ lang }) => lang === 'es',
    //         },
    //         {
    //             label_id: 'lang_english',
    //             locale: 'en',
    //             selected: ({ lang }) => lang === 'en',
    //         },
    //     ],
    // },
];

const NavigationMenu = ({ intl, closeNav, history, location }) => {
    const [navMap, setNavMap] = useState(navigationMap);
    const [{ lang }, { setLang }] = useUserState();

    useEffect(() => {
        document.querySelector('body').style.overflow = 'hidden';
        return () => {
            document.querySelector('body').style.overflow = 'auto';
        };
    }, []);

    const topDecorator = navigationMap === navMap ? 'top' : 'child';

    return (
        <Grid
            style={{
                position: 'fixed',
                top: '64px',
                left: 0,
                right: 0,
                bottom: 0,
                background: '#0e254a',
                zIndex: 2,
            }}
        >
            <Grid.Row>
                <div className="navigation-menu__top">
                    {navMap.map(item => {
                        const classNames = ['navigation-menu__top__item'];
                        classNames.push(topDecorator);
                        const selectedClassName =
                            item.selected && item.selected({ lang, location })
                                ? 'selected'
                                : '';
                        classNames.push(selectedClassName);
                        const backClassName =
                            item.type === 'back' ? 'back' : '';
                        classNames.push(backClassName);

                        return (
                            <div
                                className={classNames.join(' ')}
                                key={item.label_id}
                                onClick={() => {
                                    if (item.to) {
                                        history.push(item.to);
                                    }

                                    if (item.children) {
                                        setNavMap(item.children);
                                    } else if (item.type === 'back') {
                                        setNavMap(navigationMap);
                                    } else if (item.locale) {
                                        setLang(item.locale);
                                        closeNav();
                                    } else {
                                        closeNav();
                                    }
                                }}
                            >
                                <span>
                                    {intl.formatMessage({
                                        id: 'nav_menu_' + item.label_id,
                                        defaultMessage:
                                            defaultTranslations[
                                                'nav_menu_' + item.label_id
                                            ],
                                    })}
                                </span>
                                <img
                                    src={
                                        item.type === 'back'
                                            ? chevronLeft
                                            : chevronRight
                                    }
                                    alt={item.label_id}
                                />
                            </div>
                        );
                    })}
                </div>
            </Grid.Row>
        </Grid>
    );
};

export default withRouter(injectIntl(NavigationMenu));
