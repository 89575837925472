// @flow

import React from 'react';

import { injectIntl, type IntlShape } from 'react-intl';

import defaultTranslations from '../../../common/lang/default';
import { TELEPHONE } from '../../../common/constants/business-properties';
import telephoneIcon from '../../../img/icons/icono-telef.svg';
import whatsAppIcon from '../../../img/icons/icono-whatsapp.svg';

import './styles.scss';

type Props = {|
    intl: IntlShape,
|};

const CallUs = ({ intl }: Props) => (
    <div className="call-us__container">
        <div className="call-us__container__title">
            {intl.formatMessage({
                id: 'callUs_title',
                defaultMessage: defaultTranslations.callUs_title,
            })}
        </div>
        <div className="call-us__container__subtitle">
            {intl.formatMessage({
                id: 'callUs_subtitle',
                defaultMessage: defaultTranslations.callUs_subtitle,
            })}
        </div>
        <div className="call-us__container__details">
            <div className="call-us__container__details__block">
                <img
                    src={telephoneIcon}
                    alt="telefono"
                    className="call-us__container__details__block__icon"
                />
                <u>
                    <a
                        className="call-us__container__details__block__info"
                        href={`tel: ${TELEPHONE}`}
                    >
                        {TELEPHONE}
                    </a>
                </u>
            </div>
            <div className="call-us__container__details__block">
                <img
                    src={whatsAppIcon}
                    alt=""
                    className="call-us__container__details__block__icon"
                />
                <div className="call-us__container__details__block__info">
                    WhatsApp
                </div>
            </div>
        </div>
    </div>
);

export default injectIntl(CallUs);
