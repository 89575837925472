// @flow

export const GPS_MAIN_ADRESS = {
    lat: 38.840972,
    lng: 0.110161,
};

export const ZOOM = 17;

export const G_MAPS_KEY =
    process.env.REACT_APP_MAPS_API_KEY === "empty"
        ? ""
        : process.env.REACT_APP_MAPS_API_KEY;
