import React from 'react';
import { injectIntl } from 'react-intl';
import ObesityImg from '../../../../img/photos/ByPassBN.jpg';
import ObesityImgBlur from '../../../../img/photos/ByPassBN_blur.jpg';
import PageBase from '../../../../components/base/page';
import BaseContent from '../../common/BaseContent';

const ObesityByPassGastrico = ({ intl }) => (
    <PageBase>
        <BaseContent
            img={ObesityImg}
            imgThumb={ObesityImgBlur}
            title={intl.formatMessage({
                id: 'services_obesity-section_list-3',
                defaultMessage: 'services_obesity-section_list-3',
            })}
            subtitle={intl.formatMessage({
                id: 'service_1_subtitle',
                defaultMessage: 'service_1_subtitle',
            })}
            mainHeading="¿En qué consiste el by-pass gástrico?"
            mainDescription="El by-pass gastrico consiste en la confección de un pequeño
            estómago y bypass (salto) de 2,5-3 metros (30%) del intestino
            delgado para conseguir pérdida de peso."
        >
            <h4>Mecanismo de acción</h4>
            <p>
                Restricción del volumen del estómago y malabsorción del 30% del
                intestino delgado de todos los alimentos. Técnica mixta
                (restrictiva + malabsortiva). Supone una AYUDA para modificar
                hábitos alimentarios. acompañado de una dieta.
            </p>
            <h4>Información para el paciente</h4>
            <ul>
                <li>
                    Esta indicado para pacientes con IMC 45-60, IMC &lt; 45 con
                    mal perfil alimentario
                </li>
                <li>
                    Duración de la técnica 120 minutos con anestesia general
                </li>
                <li>Estancia en el hospital de 2-3 días</li>
                <li>Reincorporación a la actividad habitual 10-14 días</li>
                <li>
                    Seguimiento de 1 año después de la cirugía del paciente por
                    nuestro equipo. Después anualmente de por vida
                </li>
            </ul>
            <h4>Ventajas</h4>
            <p>
                La técnica para la pérdida de masa corporal es la más estudiada
                y contrastada. Muy resistente al sabotaje - consumo de alimentos
                (dulces, alcohol, picoteos).
            </p>
            <h4>Invonvenientes</h4>
            <p>
                Produce malabsorción, requiere suplementos vitamínicos de hierro
                y calcio de por vida. En pacientes con riesgo elevado tiene algo
                más de riesgo que la manga.
            </p>
            <h4>Grado de recomendación</h4>
            <p>
                Grado de recomendación con la evidencia científica disponible
                Elevado. Es la técnica considerada “gold standard” o “patrón
                oro” con la que se comparan todas las demás.
            </p>
            <h4>Resultados</h4>
            <p>Pérdida 75% del exceso de peso en 12 meses.</p>
        </BaseContent>
    </PageBase>
);

export default injectIntl(ObesityByPassGastrico);
