import React from 'react';
import Route from '../../components/sq-route';

// Pages
/* OBESIDAD */
import ObesityServices from './obesity';
import ObesityBalonGastrico from './obesity/balon-gastrico';
import ObesityPlicaturaGastrica from './obesity/plicatura-gastrica';
import ObesityMangaGastrica from './obesity/manga-gastrica';
import ObesityByPassGastrico from './obesity/by-pass';

/* LAPAROSCOPIA */
// import LaparoscopyServices from './laparoscopy';

/* MAMA */
// import BreastServices from './breast';

/* GENERAL */
// import ConventionalServices from './conventional';

/* OTHER */
import ServiciosHomeRedirect from './HomeRedirect';

const WebContainer = () => (
    <>
        <Route path="/servicios" exact render={ServiciosHomeRedirect} />

        {/* OBESIDAD */}
        <Route path="/servicios/obesidad" exact render={ObesityServices} />
        <Route
            path="/servicios/obesidad/balon-gastrico"
            exact
            render={ObesityBalonGastrico}
        />
        <Route
            path="/servicios/obesidad/plicatura-gastrica"
            exact
            render={ObesityPlicaturaGastrica}
        />
        <Route
            path="/servicios/obesidad/manga-gastrica"
            exact
            render={ObesityMangaGastrica}
        />
        <Route
            path="/servicios/obesidad/by-pass-gastrico"
            exact
            render={ObesityByPassGastrico}
        />

        {/* LAPAROSCOPIA */}
        {/* <Route
            path="/servicios/laparoscopia"
            exact
            render={LaparoscopyServices}
        />
        <Route
            path="/servicios/cirugia-de-mama"
            exact
            render={BreastServices}
        />
        <Route
            path="/servicios/cirugia-convencional"
            exact
            render={ConventionalServices}
        /> */}
    </>
);

export default WebContainer;
