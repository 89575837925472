import { createStore, createHook, createContainer } from 'react-sweet-state';
import ReactGA from 'react-ga';
import {
    USER_LANGUAGE,
    DEFAULT_LANGUAGE,
} from '../../common/constants/user-properties';
import fingerPrint from 'fingerprintjs2';
// import getSupportedLanguage from '../../common/utils/getSupportedLanguage';

const initialState = {
    lang: DEFAULT_LANGUAGE,
    userFP: '',
};

const setLang = newLang => ({ setState }) => {
    window.localStorage.setItem(USER_LANGUAGE, newLang);
    setState({ lang: newLang });
};

const actions = {
    setLang,
};

export const Store = createStore({
    initialState,
    actions,
    name: 'User Properties',
});

export const Container = createContainer(Store, {
    onInit: () => ({ setState }) => {
        // const localStorageLang = window.localStorage.getItem(USER_LANGUAGE);
        // if (!localStorageLang) {
        //     window.localStorage.setItem(USER_LANGUAGE, getSupportedLanguage());
        // }

        // setState({ lang: localStorageLang || getSupportedLanguage() });

        fingerPrint.get(components => {
            const values = components.map(function(component) {
                return component.value;
            });
            const murmur = fingerPrint.x64hash128(values.join(''), 31);

            // Add murmur to GA
            ReactGA.set({ murmur });

            setState({ userFP: murmur });
        });
    },
});

export const useState = createHook(Store);
export const useActions = createHook(Store, { selector: null });
