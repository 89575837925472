// @flow

const ES_TRANSLATIONS: { [string]: string } = {
    // HEADING
    heading_title: 'Bienvenido a Cirugía Bordallo,',
    heading_subtitle_1: 'ES UN PLACER',
    heading_subtitle_2: 'AYUDARLE',
    heading_text:
        'Especialistas en Cirugía de la obesidad y de la diabetes, Cirugía laparoscópica avanzada y Cirugía de la mama',

    // CAROUSEL
    carousel_action: 'Más Información',
    carousel_1_title: 'ESPECIALISTAS EN PRÓTESIS B-LITE',

    // FIRST FREE
    firstFree_title: 'Primera visita GRATUITA',
    firstFree_subtitle: 'para Obesidad y Estética de la mama',

    // CALL US
    callUs_title: '¿QUIERE UNA CITA CON EL DR. BORDALLO?',
    callUs_subtitle: 'Póngase en contacto con nosotros',

    // FORM
    form_title: '¿PREFIERE QUE LE CONTACTEMOS?',
    form_subtitle: 'Envíenos sus datos y sus preferencias de contacto',
    form_fullName: 'Nombre completo',
    form_email: 'Email',
    form_telephone: 'Teléfono',
    form_contactMethod: '¿Cómo prefiere que le contactemos?',
    form_motive: 'Motivo de la consulta',
    form_success: 'Su mensaje ha sido enviado con éxito.',
    form_error:
        'Hemos tenido un problema en el servidor. Inténtelo de nuevo más tarde.',
    form_send: 'Enviar',

    // CONTACT DETAILS
    contact_experience: 'Experiencia',
    contact_dedication: 'dedicación',
    contact_proficiency: 'profesionalidad',

    // CURRICULUM
    curr_header: 'Dr. Alberto Bordallo',
    curr_body:
        'Lleva más de {years} años teniendo especial dedicación a la Cirugía Laparoscópica con más de {interventions} procedimientos entre los que cabe destacar más de {hernias} de reparación de Hernia Inguinal.',
    curr_button: 'Ver Currículum',

    // SERVICIOS
    services_title: 'Servicios',
    service_1_subtitle: 'Obesidad',
    service_2_subtitle: 'Laparoscopia',
    service_3_subtitle: 'Cirugía de la mama',
    service_4_subtitle: 'Cirugía convencional',

    // SERVICIO OBESITY
    'services_obesity-section_list-0': 'Balón gástrico',
    'services_obesity-section_list-1': 'Plicatura gástrica',
    'services_obesity-section_list-2': 'Mánga gástrica',
    'services_obesity-section_list-3': 'By-pass gástrico',

    // SERVICIO LAPAROSCOPIA
    'services_laparoscopy-section_list-0': 'Colon y recto',
    'services_laparoscopy-section_list-1': 'Vesícula',
    'services_laparoscopy-section_list-2': 'Cirugía de la diabetes',
    'services_laparoscopy-section_list-3': 'Hernias',

    // SERVICIO MAMA
    'services_breast-section_list-0': 'Prótesis B-Lite',
    'services_breast-section_list-1': 'Prótesis convencional',
    'services_breast-section_list-2': 'Cáncer de mama',
    'services_breast-section_list-3': 'Cirugía benigna',
    'services_breast-section_list-4': 'Reducción de mama',
    'services_breast-section_list-5': 'Elevación de mama',

    // SERVICIO GENERAL
    'services_general-section_list-0': 'Tiroides',
    'services_general-section_list-1': 'Hemorroides',
    'services_general-section_list-2': 'Fisuras',
    'services_general-section_list-3': 'Fístulas',
    'services_general-section_list-4': 'Cirugía menor con anestesia local',

    // FOOTER
    footer_title:
        'CIRUGÍA DE LA OBESIDAD Y LA DIABETES, CIRUGÍA LAPAROSCÓPICA AVANZADA Y CIRUGÍA DE LA MAMA',

    // NAVIGATION MENU
    nav_menu_services: 'Servicios',
    nav_menu_doctor: 'Dr. Bordallo',
    nav_menu_faqs: 'Preguntas frecuentes',
    nav_menu_contact: 'Contacto',
    nav_menu_lang: 'Idioma / Language',

    // SERVICES SUB-MENU
    nav_menu_services_obesity: 'Obesidad',
    nav_menu_services_laparoscopy: 'Laparoscopia',
    nav_menu_services_breast: 'Cirugía de la Mama',
    nav_menu_services_conventional: 'Cirugía Convencional',

    // LANGUAGE SUB-MENU
    nav_menu_lang_spanish: 'Español',
    nav_menu_lang_english: 'Inglés',
};

export default ES_TRANSLATIONS;
