import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';

const PageBase = ({ children }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Grid padded="horizontally" style={{ width: '100%' }}>
            {children}
        </Grid>
    );
};

export default PageBase;
