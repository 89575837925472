import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/photos/logo_blanco.svg';
// import menuBuns from '../../img/icons/menu-blanco.svg';
// import menuClose from '../../img/icons/close-white.svg';
import { Image, Menu, Container } from 'semantic-ui-react';
import { TELEPHONE } from '../../common/constants/business-properties';
import telephonoWhite from '../../img/icons/icon-telef-white.svg';

import NavigationMenu from './drop-down';

import './styles.scss';

const FixedMenuLayout = () => {
    const [isNavMenuOpen, setNavMenuOpen] = useState(false);

    return (
        <>
            <Menu
                fixed="top"
                inverted
                style={{
                    background: '#0e254a',
                    position: 'sticky',
                }}
                borderless
                className="navigation__container"
            >
                <Container>
                    <Menu.Item
                        as={Link}
                        header
                        fitted
                        to="/"
                        onClick={() => setNavMenuOpen(false)}
                    >
                        <Image
                            src={logo}
                            alt="Logo"
                            style={{ height: '100%' }}
                            className="logo"
                        />
                    </Menu.Item>

                    {/* <Menu.Item position="right">
                        <Image
                            size="mini"
                            src={isNavMenuOpen ? menuClose : menuBuns}
                            alt="Menu desplegable"
                            style={{ padding: '4px', cursor: 'pointer' }}
                            onClick={() => setNavMenuOpen(!isNavMenuOpen)}
                        />
                    </Menu.Item> */}
                    <Menu.Item position="right">
                        <div
                            style={{
                                padding: '10px',
                                border: '1px solid white',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <img
                                src={telephonoWhite}
                                alt="telefono"
                                style={{
                                    width: '16px',
                                }}
                            />
                            <a
                                className="call-us__container__details__block__info"
                                href={`tel: ${TELEPHONE}`}
                                style={{ color: 'white', marginLeft: '12px' }}
                            >
                                Llamar
                            </a>
                        </div>
                    </Menu.Item>
                </Container>
            </Menu>
            {isNavMenuOpen ? (
                <NavigationMenu closeNav={() => setNavMenuOpen(false)} />
            ) : null}
        </>
    );
};

export default FixedMenuLayout;
