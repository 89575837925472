// @flow

import React, { type Node } from 'react';
import './styles.scss';

type Props = {
    children: Node,
    className: string,
    disabled: boolean,
    onClick: () => void,
};

const Button = ({ children, className, onClick, disabled = false }: Props) => (
    <button
        className={`${className} button__element`}
        disabled={disabled}
        onClick={onClick}
    >
        {children}
    </button>
);

export default Button;
