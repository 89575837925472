// @flow

import React from 'react';

import { injectIntl, type IntlShape } from 'react-intl';

import { BUSINESS_NAME } from '../../common/constants/business-properties';
import './style.scss';
import defaultTranslations from '../../common/lang/default';

type Props = {|
    intl: IntlShape,
|};

const Footer = ({ intl }: Props) => (
    <div className="footer__container">
        {/* <button className="footer__container__link item">Aviso legal</button>
        <button className="footer__container__link item">
            Política de privacidad
        </button> */}
        <div className="footer__container__text item">
            {intl.formatMessage({
                id: 'footer_title',
                defaultMessage: defaultTranslations.footer_title,
            })}
        </div>
        <div className="footer__container__copyright item">
            © {BUSINESS_NAME} 2018
        </div>
    </div>
);

export default injectIntl(Footer);
