import React from 'react';

import GMap from '../../components/map';
import PageBase from '../../components/base/page';
// import Services from './servicios';
import ServicesMvp from './servicios-mvp';
import FirstFree from './first-free';
import CallUs from './call-us';
import ContactDetails from './contact-details';
import CV from './curriculum';
import Carousel from './carousel';
import Form from '../../components/form';
import { Grid, Responsive } from 'semantic-ui-react';

import './styles.scss';

const Home = () => (
    <PageBase>
        <Carousel />
        {/* <Grid.Row as={Services} /> */}
        <Grid.Row as={ServicesMvp} />
        <Grid.Row>
            <Grid padded="horizontally" style={{ width: '100%' }}>
                <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    className="home__always-blue-bg"
                >
                    <Grid
                        padded="vertically"
                        columns="one"
                        reversed="mobile vertically"
                        centered
                    >
                        <Grid.Column as={CallUs} />
                        <Grid.Column as={FirstFree} />
                    </Grid>
                </Grid.Column>
                <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    className="home__form_container"
                >
                    <Form withTitle />
                </Grid.Column>
            </Grid>
        </Grid.Row>
        <Grid.Row>
            <Grid padded="horizontally" style={{ width: '100%' }}>
                <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    className="home__contact-details_container"
                >
                    <ContactDetails />
                </Grid.Column>
                <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    style={{ padding: 0 }}
                >
                    <GMap />
                </Grid.Column>
            </Grid>
        </Grid.Row>
        <Responsive
            as={Grid.Row}
            className="home__always-blue-bg"
            {...Responsive.onlyMobile}
        >
            <Grid.Column as={CV} />
        </Responsive>
    </PageBase>
);

export default Home;
