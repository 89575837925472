// @flow

import React from 'react';
import { injectIntl, type IntlShape, FormattedMessage } from 'react-intl';
import defaultTranslations from '../../../common/lang/default';
import doctorMainPhotoSmall from '../../../img/photos/dr-bordallo-main-small.png';

// import Button from '../../../components/button';
import './styles.scss';
import { Image } from 'semantic-ui-react';

type Props = {|
    intl: IntlShape,
|};

const FirstFree = ({ intl }: Props) => (
    <div className="curriculum__container">
        <div className="curriculum__container__title">
            {intl.formatMessage({
                id: 'curr_header',
                defaultMessage: defaultTranslations.curr_header,
            })}
        </div>
        <div className="curriculum__container__mid">
            <div className="curriculum__container__mid__subtitle">
                <FormattedMessage
                    id="curr_body"
                    description="Greeting to welcome the user to the app"
                    defaultMessage={defaultTranslations.curr_body}
                    values={{
                        years: <b>26</b>,
                        interventions: <b>4000</b>,
                        hernias: <b>1600</b>,
                    }}
                />
            </div>
            <Image src={doctorMainPhotoSmall} />
        </div>
        {/* <Button>
            {intl.formatMessage({
                id: 'curr_button',
                defaultMessage: defaultTranslations.curr_button,
            })}
        </Button> */}
    </div>
);

export default injectIntl(FirstFree);
