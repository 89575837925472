// @flow

// Global Constants
export const USER_LANGUAGE = "user_lang";

// Supported languages
export const ESP = "es";
export const ENG = "en";

// Map
export const SUPPORTED_LANGUAGES = {
    es: ESP,
    en: ENG
};

// Default language
export const DEFAULT_LANGUAGE = ESP;
