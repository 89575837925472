// @flow

const EN_TRANSLATIONS: { [string]: string } = {
    // HEADING
    heading_title: 'Welcome to Cirugía Bordallo,',
    heading_subtitle_1: 'IT IS OUR PLEASURE',
    heading_subtitle_2: 'TO ASSIST YOU',
    heading_text:
        'Specialists in obesity and diabetes surgery, advanced laparoscopy and breast surgery',

    // CAROUSEL
    carousel_action: 'More information',
    carousel_1_title: 'SPECIALISED IN B-LITE IMPLANTS',

    // FIRST FREE
    firstFree_title: 'First visit FREE',
    firstFree_subtitle: 'for Obesity and breast Aesthetics',

    // CALL US
    callUs_title: 'DO YOU WANT AN APPOINTMENT WITH DR. BORDALLO?',
    callUs_subtitle: 'Get in contact with us',

    // FORM
    form_title: 'WOULD YOU RATHER WE CONTACT YOU?',
    form_subtitle: 'Send us your details and your preferences',
    form_fullName: 'Full name',
    form_email: 'Email',
    form_telephone: 'Telephone',
    form_contactMethod: 'How would you prefer we contact you?',
    form_motive: 'Motive for the appointment',
    form_success: 'Your message has been successfully sent.',
    form_error: 'There has been a problem in our servers. Try again later.',
    form_send: 'Send',

    // CONTACT DETAILS
    contact_experience: 'Experience',
    contact_dedication: 'dedication',
    contact_proficiency: 'proficiency',

    // CURRICULUM
    curr_header: 'Dr. Alberto Bordallo',
    curr_body:
        'Over {years} years of focused dedication to laparoscopic surgery with, have undertaken more than {interventions} interventinos among which +{hernias} where inguinal hernia repairs.',
    curr_button: 'See Curriculum',

    // SERVICIOS
    services_title: 'Services',
    service_1_subtitle: 'Obesity',
    service_2_subtitle: 'Laparoscopy',
    service_3_subtitle: 'Breast Surgery',
    service_4_subtitle: 'General Surgery',

    // FOOTER
    footer_title:
        'GENERAL SURGERY, SURGERY FOR OBESITY AND DIABETES, LAPAROSCOPY AND BREAST SURGERY',

    // NAVIGATION MENU
    nav_menu_services: 'Services',
    nav_menu_doctor: 'Dr. Bordallo',
    nav_menu_faqs: 'Frequent questions',
    nav_menu_contact: 'Contact',
    nav_menu_lang: 'Idioma / Language',

    // SERVICES SUB-MENU
    nav_menu_services_obesity: 'Obesity',
    nav_menu_services_laparoscopy: 'Laparoscopy',
    nav_menu_services_breast: 'Breast surgery',
    nav_menu_services_conventional: 'General surgery',

    // LANGUAGE SUB-MENU
    nav_menu_lang_spanish: 'Spanish',
    nav_menu_lang_english: 'English',
};

export default EN_TRANSLATIONS;
