import React from 'react';
import { injectIntl } from 'react-intl';
import ObesityImg from '../../../../img/photos/MangaBN.jpg';
import ObesityImgBlur from '../../../../img/photos/MangaBN_blur.jpg';
import PageBase from '../../../../components/base/page';
import BaseContent from '../../common/BaseContent';

const ObesityMangaGastrica = ({ intl }) => (
    <PageBase>
        <BaseContent
            img={ObesityImg}
            imgThumb={ObesityImgBlur}
            title={intl.formatMessage({
                id: 'services_obesity-section_list-2',
                defaultMessage: 'services_obesity-section_list-2',
            })}
            subtitle={intl.formatMessage({
                id: 'service_1_subtitle',
                defaultMessage: 'service_1_subtitle',
            })}
            mainHeading="¿En qué consiste la Manga Gástrica?"
            mainDescription="La operacion de manga gástrica consiste en la extirpación del
            80% del estómago del paciente, que queda con forma de tubo o
            embudo."
        >
            <h4>Mecanismo de acción</h4>
            <p>
                Restricción definitiva del volumen del estómago de los pacientes
                al extirpar el 70-80% y extraerlo del cuerpo.{' '}
            </p>
            <p>
                Como cualquier otra cirugía bariátrica, supone una ayuda a
                modificar hábitos alimentarios y la pérdida de peso. Produce
                diminución de la ingesta y saciedad precoz.
            </p>
            <h4>Información para el paciente</h4>
            <ul>
                <li>
                    Indicado en pacientes con un IMC de 35-45 e IMC &gt; 55-60
                    (o factores de riesgo elevados)
                </li>
                <li>Duración de la técnica 90 minutos</li>
                <li>Estancia en el hospital 2 días</li>
                <li>Reincorporación a la actividad habitual 7-10 días</li>
                <li>
                    Seguimiento del paciente durante 1 año por nuestro equipo y
                    posteriormente anual de por vida
                </li>
            </ul>
            <h4>Ventajas</h4>
            <p>
                No produce malabsorción como el bypass. No requiere vitaminas de
                por vida.Más efectiva que la plicatura y las técnicas
                restrictivas endoscópicas.Para IMC &lt; 45 y perfil gran
                comedor, eficacia comparable al bypass.
            </p>
            <h4>Invonvenientes</h4>
            <p>
                Menos efectiva que el bypass en IMC &gt; 45 o con perfiles
                alimentarios malos (dulces, alcohol, picoteadores).
            </p>
            <p>
                Complicaciones y riesgo comparado Riesgo como una
                colecistectomía laparoscópica.Complicaciones a corto plazo: fuga
                (3%) y sangrado (2%)A largo plazo: recuperación de peso 15%
                (sabotaje)
            </p>
            <h4>Grado de recomendación</h4>
            <p>
                Con la evidencia científica disponible elevado. Una de las 2
                técnicas más utilizadas en todo el mundo (junto al bypass)
            </p>
            <h4>Resultados</h4>
            <p>Pérdida del 75% del exceso de peso a los 9 meses.</p>
        </BaseContent>
    </PageBase>
);

export default injectIntl(ObesityMangaGastrica);
